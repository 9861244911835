import { useStaticQuery, graphql } from 'gatsby';

import { IAbout, IAccordionItem } from '../../types/data.types';

export const useAboutData = (): IAbout => {
	const data = useStaticQuery(graphql`
		query {
			craft {
				about: entry(section: "about") {
					id
					seomatic {
						metaTitleContainer
						metaTagContainer
						metaLinkContainer
						metaJsonLdContainer
					}
					... on Craft_about_about_Entry {
						title
						content: description
						linkedAssets {
							... on Craft_linkedAssets_linkedAsset_BlockType {
								id
								linkedSentence
								image {
									... on Craft_assetsAws_Asset {
										title
										url
										urlWebp600: url(width: 600, format: "webp")
										urlWebp300: url(width: 300, format: "webp")
										urlWebp150: url(width: 150, format: "webp")
										urlJpg600: url(width: 600, format: "jpg")
										urlJpg300: url(width: 300, format: "jpg")
										urlJpg150: url(width: 150, format: "jpg")
										width
										height
									}
								}
							}
						}
						accordion {
							... on Craft_accordion_accordionItem_BlockType {
								id
								title: accordionItemTitle
								content: description
								showCareers
							}
						}
					}
				}
				careers: entries(section: "careers", level: 1) {
					... on Craft_careers_default_Entry {
						slug
						title
					}
				}
				defaultPages: entries(level: 1) {
					... on Craft_default_default_Entry {
						title
						slug
					}
				}
			}
		}
	`);

	const pageData = data.craft;

	const accordion = pageData.about.accordion.map(
		(item: any): IAccordionItem => ({
			...item,
			...(item.showCareers && {
				careers: pageData.careers,
			}),
		}),
	);

	const linkedAssets = pageData.about.linkedAssets.map((asset: any) => ({
		...asset,
		image: asset.image[0],
	}));

	const defaultPages = pageData.defaultPages.filter((page: any) => page?.slug);

	return {
		...pageData.about,
		defaultPages,
		accordion,
		linkedAssets,
	};
};
