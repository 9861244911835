import React, { useEffect, useMemo, useState } from 'react';

import cx from 'classnames';

import {
	Accordion,
	Header,
	HEADER_TYPES,
	RichText,
	SEO,
	Image,
	LinkList,
	ILink,
	Portal,
	FullHeightWrapper,
} from '../components';
import { SCROLL_KEYS } from '../const/locomotiveScroll.const';
import { IAppContext } from '../context/app/app.types';
import { ILocomotiveScrollContext } from '../context/locomotiveScroll/locomotiveScroll.types';
import { useAppContext } from '../hooks/context/appContext.hook';
import { useLocomotiveScrollContext } from '../hooks/context/locomotiveScroll.hook';
import { useAboutData } from '../hooks/data/about.hook';
import { IAbout, ILinkedAsset, IPageInfo } from '../types/data.types';
import { checkIsInview } from '../utils/checkIsInview.util';
import getWindowSize from '../utils/getWindow.util';
import { isScrollEnabled } from '../utils/isScrollEnabled.util';

const AboutPage = () => {
	const [activeImage, setActiveImage] = useState<string>('');
	const { scroll }: ILocomotiveScrollContext = useLocomotiveScrollContext();
	const sizes = getWindowSize();
	const hasSmoothScroll = isScrollEnabled();

	const { content, linkedAssets, accordion, defaultPages, seomatic }: IAbout =
		useAboutData();
	const { setIsGridVisible }: IAppContext = useAppContext();

	/**
	 * FUNCTIONS
	 */
	const onToggleAccordionItem = (): void => {
		if (scroll) {
			scroll.update();
		}
	};

	const handleScrollImages = (args: any): void => {
		const { y } = args.scroll;
		const isFooterInview = checkIsInview(args, SCROLL_KEYS.FOOTER);
		let scrollElements = args.currentElements;

		if (y <= 0 || isFooterInview || !sizes) {
			setActiveImage('');
			return;
		}

		if (!hasSmoothScroll) {
			scrollElements = document.querySelectorAll('[data-scroll-id]');
			scrollElements.forEach((el: HTMLElement): void => {
				const progress = el.getBoundingClientRect().top / sizes.height;
				const id = el.dataset.scrollId;

				if (progress > 0.2 && progress < 1 && id) {
					setActiveImage(id);
				}
			});
			return;
		}

		Object.keys(scrollElements).forEach((id: string): void => {
			const { progress } = scrollElements[id];

			if (progress > 0.2) {
				setActiveImage(id);
			}
		});
	};

	/**
	 * HOOKS
	 */
	const linkList = useMemo((): ILink[] => {
		if (!defaultPages) {
			return [];
		}

		return defaultPages.map(({ slug, title }: IPageInfo) => ({
			label: title,
			to: slug,
			isExternal: false,
		}));
	}, [defaultPages]);

	const pageContent = useMemo((): string => {
		if (content === null || content === undefined) {
			return '';
		}

		if (linkedAssets?.length) {
			let updatedContent = content.toLowerCase();

			linkedAssets.forEach(({ id, linkedSentence }: ILinkedAsset): void => {
				updatedContent = updatedContent.replace(
					linkedSentence.toLowerCase(),
					`<span data-scroll data-scroll-id=${id}>${linkedSentence}</span>`,
				);
			});

			return updatedContent;
		}

		return content;
	}, [content, linkedAssets]);

	useEffect(() => {
		if (!hasSmoothScroll) {
			// Info(Katia): Fallback for low performance
			if (typeof window !== 'undefined') {
				window.addEventListener('scroll', (e) => {
					const args = {
						scroll: {
							y: window.scrollY,
						},
					};
					handleScrollImages(args);
				});
			}
			return;
		}

		if (!scroll) {
			return;
		}

		scroll.on('scroll', (args: any) => {
			handleScrollImages(args);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scroll, hasSmoothScroll]);

	useEffect(() => {
		setIsGridVisible(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * RENDER
	 */
	return (
		<div className="u-mb-10">
			<SEO {...seomatic} />

			<div data-scroll-section>
				<Header type={HEADER_TYPES.Dark} />
			</div>

			<main className="u-stack u-layout">
				<section data-scroll-section>
					<RichText content={pageContent} className="u-font-s-md" />
				</section>
				<section data-scroll data-scroll-section>
					<Accordion items={accordion} onToggle={onToggleAccordionItem} />
				</section>
			</main>

			<footer
				className="u-layout u-mt-10"
				data-scroll-section
				data-scroll
				data-scroll-id={SCROLL_KEYS.FOOTER}>
				<LinkList links={linkList} />
			</footer>

			<Portal>
				<FullHeightWrapper className="c-fly-img__wrapper u-pos-fixed u-pos-fixed--fill">
					{linkedAssets?.map(({ id, image }: ILinkedAsset, i: number) => (
						<Image
							key={id}
							image={image}
							imageClassName="c-fly-img"
							srcsetSizes={[600, 300, 150]}
							sizes="(min-width: 990px) 50vw, 100vw"
							wrapperClassName={cx(
								i % 2 === 0
									? `c-fly-img--left u-left-${i * 2}`
									: `c-fly-img--right u-right-${i * 2}`,
								`c-fly-img--${
									activeImage === id ? 'active' : 'inactive'
								}`,
							)}
						/>
					))}
				</FullHeightWrapper>
			</Portal>
		</div>
	);
};

export default AboutPage;
